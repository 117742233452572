export interface AppAdministratorDashboardRootUserInformationFormUpdate {
  id: string;
  content: {
    name: string;
    surname: string;
    birthday: string;
    position: string;
    height: number;
    weight: number;
    avatar: string;
    contract: {
      club: string,
      from: string,
      to: string,
      position: string;
    };
  };
}

export class AppAdministratorDashboardRootUserInformationFormUpdate implements AppAdministratorDashboardRootUserInformationFormUpdate {
  id: string;
  content: {
    name: string;
    surname: string;
    birthday: string;
    position: string;
    height: number;
    weight: number;
    avatar: string;
    contract: {
      club: string,
      from: string,
      to: string,
      position: string;
    };
  };

  constructor() {
    this.id = "";
    this.content = {
      name: "",
      surname: "",
      birthday: "",
      position: "",
      height: null,
      weight: null,
      avatar: null,
      contract: {
        club: "",
        from: "",
        to: "",
        position: "",
      },
    };
  }
}