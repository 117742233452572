
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Components
import {
  OfficeBuilding,
  DataBoard,
  Football,
  DataLine,
  DataAnalysis,
  FirstAidKit,
  User,
  FolderOpened,
} from "@element-plus/icons-vue";
import AuthenticationLogoutIndexVue from "@/views/authentication/logout/index.vue";
import SwitchOrganizationIndexVue from "@/components/switch-organization.vue";

@Options({
  components: {
    OfficeBuilding,
    DataBoard,
    Football,
    DataLine,
    DataAnalysis,
    FirstAidKit,
    User,
    FolderOpened,
    AuthenticationLogoutIndexVue,
    SwitchOrganizationIndexVue,
  },
})
export default class AdministratorNavigationVue extends Vue {
  isNavigationListVisible = false;
  exactRoute = "";

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppAdministratorOrganizationsRootIndexVue":
      case "AppAdministratorOrganizationsDetailIndexVue":
        this.exactRoute = "organizations";
        break;
      case "AppAdministratorDashboardRootIndexVue":
      case "AppAdministratorDashboardStatisticsIndexVue":
      case "AppAdministratorDashboardCalendarIndexVue":
        this.exactRoute = "dashboard";
        break;
      case "AppAdministratorMatchesRootIndexVue":
      case "AppAdministratorMatchesCategoriesIndexVue":
        this.exactRoute = "matches";
        break;
      case "AppAdministratorTrainingsRootIndexVue":
      case "AppAdministratorTrainingsCategoriesIndexVue":
        this.exactRoute = "trainings";
        break;
      case "AppAdministratorDiagnosticsRootIndexVue":
      case "AppAdministratorDiagnosticsCategoriesIndexVue":
        this.exactRoute = "diagnostics";
        break;
      case "AppAdministratorHealthRecordsRootIndexVue":
        this.exactRoute = "health-records";
        break;
      case "AppAdministratorPlayersRootIndexVue":
      case "AppAdministratorPlayersCategoriesIndexVue":
      case "AppAdministratorPlayersProfileRootIndexVue":
      case "AppAdministratorPlayersProfileStatisticsIndexVue":
      case "AppAdministratorPlayersProfileMatchesIndexVue":
      case "AppAdministratorPlayersProfileTrainingsIndexVue":
      case "AppAdministratorPlayersProfileDiagnosticsIndexVue":
      case "AppAdministratorPlayersProfileHealthRecordsIndexVue":
      case "AppAdministratorPlayersProfileTipsIndexVue":
      case "AppAdministratorPlayersProfileNotesIndexVue":
        this.exactRoute = "players";
        break;
      case "AppAdministratorResourcesRootIndexVue":
      case "AppAdministratorResourcesSupplementsIndexVue":
      case "AppAdministratorResourcesDietsIndexVue":
      case "AppAdministratorResourcesExercisesIndexVue":
        this.exactRoute = "resources";
        break;
    }
  }

  get hasUserOrganizationMemberships(): boolean {
    return store.getters.getOrganizationMembership.length > 1;
  }

  created(): void {
    this.setExactRoute();
  }
}
